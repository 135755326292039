class Stock {
    c: number[];
    h: number[];
    l: number[];
    o: number[];
    s: string;
    t: number[];
    v: number[];

    constructor(){
        this.c = [];
        this.h = [];
        this.l = [];
        this.o = [];
        this.s = 'no_data';
        this.t = [];
        this.v = [];
    }
}
export {};
export default Stock;